<template>
  <div>
    <notifications group="notification" position="top right" />
    <MaintenanceWizard
      v-if="isMaintenanceWizardReady"
      :selected-device-id="selectedDeviceId"
      :selected-device="selectedDevice"
      :is-single="true"
    ></MaintenanceWizard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import MaintenanceWizard from '@/view/pages/devices/_components/MaintenanceWizard'
import ApiService from '@/core/services/api.service'

export default {
  name: 'DevicesMaintenance',
  components: {
    MaintenanceWizard,
  },
  data() {
    return {
      selectedDeviceId: null,
      selectedDevice: null,
      isMaintenanceWizardReady: false,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Devices', route: '/devices/maintenance' },
      { title: 'Device Maintenance' },
    ])

    ApiService.get('devices', this.$route.params.id).then(({ data }) => {
      this.selectedDevice = data
      this.selectedDeviceId = '/api/v1/devices/' + this.$route.params.id
      this.isMaintenanceWizardReady = true
    })
  },
  methods: {},
}
</script>
