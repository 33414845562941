<template>
  <span :class="['switch', classes]">
    <label>
      <input
        type="checkbox"
        :checked="isChecked"
        @click="toggleSwitch(checked)"
        :name="name"
      />
      <span></span>
    </label>
  </span>
</template>

<script>
export default {
  name: 'VtSwitch',
  props: {
    isChecked: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      checked: null,
    }
  },
  mounted() {
    this.checked = this.isChecked
  },
  methods: {
    toggleSwitch(isPublish) {
      this.checked = !isPublish
      this.$emit('checkedEvent', this.checked)
    },
  },
}
</script>
